import React from "react"

const Jumbotron = () => (
  <div className="jumbotron">
    <div className="container">
      <h1 className="display-3">
        <span className="inline-spanner">
          Modern,
          <br />
          homestyle,
          <br />
          Lebanese.
        </span>
      </h1>
      <p>
        <span className="inline-spanner">
          Take-out available from our café in Magnolia!
        </span>
      </p>
      <p>
        <a
          className="btn btn-primary btn-lg"
          href="https://www.exploretock.com/damoorikitchen/experience/221790/menu/damoori-kitchen-to-go"
          target="_blank"
          rel="noreferrer"
        >
          Order online &raquo;
        </a>
      </p>
    </div>
  </div>
)

export default Jumbotron

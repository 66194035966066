import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"
import {
  googleMapsLink,
  cateringGoogleFormsLink,
  tockBoxedLunchesLink,
} from "../constants"
import { PhoneIcon, PhoneFillIcon, ShopIcon, ClockIcon, } from "../components/icons"

export const NewBadge = ({ style }) => {
  const defaultStyle = {
    backgroundColor: "#385e9d",
    borderRadius: "0.5rem",
  }
  return (
    <span
      className="badge badge-success"
      style={Object.assign({}, defaultStyle, style)}
    >
      New!
    </span>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main role="main">
      <Jumbotron />
      <div className="container container-main">
        <div className="row">
          <div className="col-md-12">
            <h2 className="display-4">Lebanese Café and Market</h2>

            <div className="lead" style={{ marginBottom: "1em" }}>
              <div>
                <ShopIcon />
                <a
                  href={googleMapsLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "0.5em" }}
                >
                  3101 W Jameson St, Seattle
                </a>
              </div>
              <div>
                <ClockIcon />
                <span style={{ marginLeft: "0.5em" }}>
                  Open Thursday - Sunday, 11:30am - 7pm
                </span>
              </div>
              <div>
                <PhoneIcon />
                <a
                  href="tel:206-257-5947"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "0.5em" }}
                >
                  <span>(206)-257-5947</span>
                </a>
              </div>
            </div>

            <p>
              Our café and market has a takeout menu featuring Lebanese pita
              sandwiches, salads, and entreés; a fridge full of grab-and-go
              items such as dips, pre-cooked entrees and salads; Mediterranean wine and Northwest
              beer; and a growing selection of goods imported
              from or inspired by the Levant and the wider Mediterranean.
            </p>

            <p>
              We are also now serving Lebanese Style Rotisserie Chicken with Garlic Toum!
              Order online one day in advance.&nbsp;
              <a href="pdf/rotisserie-chicken-menu.pdf" target="_blank">See our Rotisserie Chicken menu</a> for more information.
            </p>

            <p>
              Come visit us in the Lawton Park area of Magnolia, Seattle, just
              off Government Way near Discovery Park. Only five minutes from the
              Ballard Bridge!
            </p>

            <p>
              <strong>
                Order in person or call ahead at <PhoneFillIcon /> 206-257-5947
              </strong>
            </p>

            <p>
              <em>
                Takeout orders are generally ready in 15 minutes, sometimes
                longer for large orders and during busy periods
              </em>
            </p>

            <div style={{ margin: "2em 0" }}>
              <a
                href="pdf/menu.pdf"
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                View Takeout Menu
              </a>
              <a
                href="pdf/rotisserie-chicken-menu.pdf"
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                View Rotisserie Chicken Menu
              </a>
              <a
                href="https://www.exploretock.com/damoorikitchen/"
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                <NewBadge /> Order Online for Pickup
              </a>
              <a
                href="tel:1-206-257-5947"
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                Call <PhoneFillIcon /> 206-257-5947
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h2 className="display-4">Catering</h2>

            <p>
              Let us cater your next gathering or work event!
            </p>
            <p>
              Build an order off our{" "}
              <a href="/pdf/menu.pdf" target="_blank" rel="noreferrer">
                takeout menu
              </a>
              , use one of our <Link to="/featured-menus">featured menus</Link>,
              order{" "}
              <a href={tockBoxedLunchesLink} target="_blank" rel="noreferrer">
                boxed lunches for work
              </a>
              , or build a custom menu using our{" "}
              <a href={cateringGoogleFormsLink} target="">
                convenient form
              </a>
              !
            </p>
            <p>
              Email{" "}
              <a href="hello@damoorikitchen.com">hello@damoorikitchen.com</a>{" "}
              for any questions!
            </p>
            <div style={{ margin: "2em 0" }}>
              <Link
                to="/featured-menus"
                className="btn btn-primary btn-lg btn-block"
              >
                Featured Catering Menus
              </Link>
              <a
                href={tockBoxedLunchesLink}
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                Boxes Lunches for Work
              </a>
              <a
                href={cateringGoogleFormsLink}
                className="btn btn-primary btn-lg btn-block"
                target="_blank"
                rel="noreferrer"
              >
                Build-A-Menu
              </a>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "2em" }}>
          <div className="col-md-12">
            <h2 className="display-4">Wholesale sales</h2>
            <p>
              We offer wholesale pricing on our Damoori Kitchen products such as dips, falafel,
              and baked goods; as well as imported Levantine products such as olive oils, spices,
              and fine Lebanese wine.
            </p>
            <p>
              Contact <a href="mailto:hello@damoorikitchen.com">hello@damoorikitchen.com</a> for our price
              list!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{ margin: "1em 0 0 0" }}>
            <h2
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Join our email list for updates and more!
            </h2>
            <iframe
              style={{
                width: "100%",
                height: "400px",
                border: 0,
              }}
              title="mailchimp-form"
              src={"/html/mailchimp-form.html"}
            />
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default IndexPage
